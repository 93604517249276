import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { APP_URL } from '../App';



const Logout = () => {
    const navigate = useNavigate();

  useEffect( () => {
     logout()

   

    
  }, [navigate]);

  const logout= async()=>{
    try{
      const response =  axios.post(APP_URL+"logout.php",{},

       {headers: {
         'Content-Type': 'application/json',
         'X-API-Key': localStorage.getItem('token')
       }})
     

       if(response.data.status === "success"){
         localStorage.removeItem('loggedin');
         localStorage.removeItem('token');          
         navigate("/login");
       }else{
         alert(response.data.message);
       }
   }catch(error){
    localStorage.removeItem('loggedin');
    localStorage.removeItem('token');          
    navigate("/login");

   }
  }

  return (
    <>
    Please wait loggin out...
    </>
  );
};

export default Logout;