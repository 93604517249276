import React, { Component } from 'react';
import { Link,NavLink } from 'react-router-dom';
export default class Sidebar extends Component {
  render() {
    return (

      <div>

        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          <ul className="nav">
            <li className="nav-item ">
            <NavLink to="/" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-grid menu-icon" />
                <span className="menu-title">Dashboard</span>
              </NavLink>
            </li>

            <li className="nav-item">
            <NavLink to="/blockapp" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-circle-minus menu-icon" />
                <span className="menu-title">Block App</span>
              </NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/unblockapp" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-circle-plus menu-icon" />
                <span className="menu-title">Unblock App</span>
              </NavLink>
            </li>
       

            <li className="nav-item">
            <NavLink to="/lockDevice" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-lock menu-icon" />
                <span className="menu-title">Lock Device</span>
              </NavLink>
            </li>
            <li className="nav-item">
            <NavLink to="/unlockDevice" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-unlock menu-icon" />
                <span className="menu-title">Unlock Device</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/notify" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-bell menu-icon" />
                <span className="menu-title">Notify</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/release" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-minus menu-icon" />
                <span className="menu-title">Release</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/deviceDetails" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-align-justify menu-icon" />
                <span className="menu-title">Device Details</span>
              </NavLink>
            </li>
           
           
            
            {/* <li className="nav-item">
              <NavLink to="/report" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-file menu-icon" />
                <span className="menu-title">Report</span>
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink to="/reports" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")} >
                <i className="icon-file menu-icon" />
                <span className="menu-title">Reports</span>
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" data-bs-toggle="collapse" href="#editors" aria-expanded="false" aria-controls="editors">
                <i className="icon-book menu-icon" />
                <span className="menu-title">My Returns</span>
                <i className="menu-arrow" />
              </a>
              <div className="collapse" id="editors">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link to="/sales_return" className="nav-link">Sales Return</Link></li>
                  <li className="nav-item">
                    <Link to="/purchase_return" className="nav-link" >Purchase Retun</Link></li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="editors">
                <i className="icon-book menu-icon" />
                <span className="menu-title">Customer Relation</span>
                <i className="menu-arrow" />
              </a>
              <div className="collapse" id="ui-basic">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link to="/my_customer" className="nav-link" >My Customers</Link></li>
                  <li className="nav-item">
                    <Link to="/my_distributor" className="nav-link" >My Distributors</Link></li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="collapse" href="#ui-advanced" aria-expanded="false" aria-controls="editors">
                <i className="icon-book menu-icon" />
                <span className="menu-title">Schemes Mgmt</span>
                <i className="menu-arrow" />
              </a>
              <div className="collapse" id="ui-advanced">
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link to="/scheme_dashboard" className="nav-link" >Schemes Dashboard</Link></li>
                  <li className="nav-item">
                    <Link to="/add_your_scheme" className="nav-link" >Add Your Schemes</Link></li>
                  <li className="nav-item">
                    <Link to="/my_earnings" className="nav-link" >My Earnings</Link></li>
                  <li className="nav-item"><a className="nav-link" href="<?php echo base_url();?>my_retailer_types">My Retailer Types</a></li>
                </ul>
              </div>
            </li> */}
            {/* <li className="nav-item">
              <Link to="/ledger" className="nav-link" >
                <i className="icon-grid menu-icon" />
                <span className="menu-title">Ledger</span>
              </Link>
            </li> */}

           
            <li className="nav-item">
            <NavLink to="/logout" className={({ isActive }) => (isActive ? "nav-link-active" : "nav-link")}>
                <i className="icon-power menu-icon" />
                <span className="menu-title">Logout</span>
              </NavLink>
            </li>
          </ul>
        </nav>

      </div>




    );
  }
}
