import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { decryptResponse, encryptPayload } from './CryptoUtil';
import * as XLSX from 'xlsx';


const History = () => {
  const [selectedAction, setSelectedAction] = useState('lock');
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [wait, setWait] = useState(false);
  const[selectedRegistration,setSelectedRegistration]= useState('');
  const [startdate, setStartdate] = useState([]);
  const [enddate, setEnddate] = useState([]);
  const [items, setItems] = useState([]);



  const handleTabClick = (action) => {
    setSelectedAction(action);
    setStartdate('')
    setEnddate('')
    setItems([])
  };

  const handleSubmit=async (e)=>{
    e.preventDefault();

    setWait(true)

    if(startdate > enddate){
      setWait(false)
      return alert("End date cannot be prior to start date")
    }
   
    let payload="";

    if(selectedAction==='register'){
      payload= encryptPayload({
        "report":selectedAction,
        "startdate":startdate,
        "enddate":enddate,
        "match":selectedRegistration
      })

      console.log(encryptPayload({
        "report":selectedAction,
        "startdate":startdate,
        "enddate":enddate,
        "match":selectedRegistration
      }))
      
    }else{
      payload=encryptPayload({
        "report":selectedAction,
        "startdate":startdate,
        "enddate":enddate,       
      })
      
    }
    try{
      const response= await axios.post('https://www.screenbros.co/webapi/downloadReports.php',
        payload ,
        {headers: {
          'Content-Type': 'application/json',
          'X-API-Key': localStorage.getItem('token')
        }})

   
      

      const decryptText = decryptResponse(response.data.payload,response.data.transactionId)
    
      if(decryptText.status=="success"){
        setItems(decryptText.items)
      }else{
        alert(decryptText.message)
      }
    }catch(error){
      alert("Something went wrong.Please try again in sometime")
    }
    setWait(false)
    
      
    
  }

  const handleFilter=(e)=>{
    setSelectedRegistration(e.target.value)
  }

  const generateExcel=()=>{
    const workbook = XLSX.utils.book_new();
    console.log(items)

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(items);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "registrations");
    let found = "Matched"

    if(selectedAction=="NO"){
      found= "Unmatched"     

    }else if(selectedAction=="ALL"){
      found="All"
    }

    // Generate the Excel file and trigger a download
    const filename = found+'-'+startdate+'-'+enddate+'.xlsx'
    XLSX.writeFile(workbook, filename);

  }
  return (
    <div className='row'>
      <div className="col-md-12 col-xl-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Bulk Upload History Details</h4>
            <ul className="nav nav-pills nav-pills-success" id="pills-tab" role="tablist">
            <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'register' ? 'active' : ''}`}
                  id="pills-notify-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('register')}
                >
                  Registrations
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'lock' ? 'active' : ''}`}
                  id="pills-lock-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('lock')}
                >
                  Lock  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'unlock' ? 'active' : ''}`}
                  id="pills-unlock-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('unlock')}
                >
                  Unlock  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'release' ? 'active' : ''}`}
                  id="pills-release-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('release')}
                >
                  Release  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'notify' ? 'active' : ''}`}
                  id="pills-notify-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('notify')}
                >
                  Notify  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'block' ? 'active' : ''}`}
                  id="pills-notify-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('block')}
                >
                  Block  History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link my-1 ${selectedAction === 'unblock' ? 'active' : ''}`}
                  id="pills-notify-tab"
                  data-toggle="pill"
                  onClick={() => handleTabClick('unblock')}
                >
                  Unblock  History
                </button>
              </li>
            </ul>
            <div className="tab-content mt-4" id="pills-tabContent">
              <div className='row'>
              <div className="col-md-12 grid-margin transparent">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Download reports : {selectedAction}</h4>
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className='row'>
                  <div className="col-sm-4">
                  <div className="form-group">
                  <label>Start Date</label>
                  <div className="col-sm-12">
                  <input type="date" placeholder="startdate" onChange={(e)=>setStartdate(e.target.value)} className="form-control   input-sm "  required  />
                   
                  </div>
                </div>

                  </div>
                  <div className="col-sm-4">
                      <div className="form-group">
                      <label>End Date</label>
                      <div className="col-sm-12">
                      <input type="date"  placeholder="Select End Date"  onChange={(e)=>setEnddate(e.target.value)} className="form-control  input-sm "  required  />
                      
                      </div>
                    </div>
                    
                  </div>

                  {selectedAction === 'register'?
                  <div className='col-sm-3'>
                <div className="form-group">
                  <label>Select Registration Filter</label>
                  <div className="col-sm-12">
                  <select className="form-control  input-sm "  required value={selectedRegistration}
                               onChange={handleFilter}>
                      <option value="">----Choose Registration Filter----</option>
                      <option value="ALL">All</option>
                      <option value="YES">Matched</option>
                      <option value="NO">Unmatched</option>
                     
                    </select>
                   
                  </div>
                  </div>
                </div>
                :''}




                </div>
                


               
               



                <div className="submit-buttons">
                  <button type="submit" className="btn btn-success me-2 btn-sm text-white" >
                    Submit
                  </button>
                  <button className="btn btn-danger btn-sm text-white" >
                    Cancel
                  </button>
                </div>

                {wait ?
                <>
                <p>Please wait while we fetch the data 
                  </p>
                  </>:''
                  }
              </form>


              {selectedAction !='register' && items.length>0 ?
              <>
                <table className="table table-striped table-borderless">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Date</th>
                        <th>File Name</th>
                        <th>Original File Name</th>
                        <th>Status of File</th>
                        <th>Last Imei</th>
                        <th>Remarks</th>
                        <th>Download</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.date}</td>
                          <td>{item.file}</td>
                          <td>{item.oldfile}</td>
                          <td>{item.isComplete}</td>
                          <td>{item.lastimei}</td>
                          <td>{item.remarks}</td>
                          <td><a href={'../webapi/'+selectedAction+'/'+item.file}> Download File</a></td>
                         
                        </tr>
                      ))}
                    </tbody>
                  </table>
                
              
              </>

              :<></>
              
            }


            {selectedAction =='register' && items.length>0 ?
            <>
            <br></br>
            <hr></hr>
             
             <button onClick={generateExcel} className="btn btn-info btn-sm text-white" >
               Click here to Download file
             </button>
             <hr></hr>
            
            </>
            
            
             :         
          ''
              
              
            }




            </div>
          </div>
        </div>

              </div>
        
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
