import React, { Component } from 'react'

export default class Home extends Component {
  render() {
    return (
     <div>
  <div className="row">
    <div className="col-md-12 grid-margin">
      <div className="row">
        <div className="col-12 col-xl-8 mb-4 mb-xl-0">
         
          
        </div>
        <div className="col-12 col-xl-4">
          <div className="justify-content-end d-flex">
            <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                
              
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-12 grid-margin transparent">
      <div className="row">
        <div className="col-md-6 mb-4 stretch-card transparent">
          <div className="card card-tale">
            <div className="card-body">
           
              <p className="fs-30 mb-2">Download Sample CSV</p>
              <a href='../samplecsv.csv'>Sample Csv</a>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4 stretch-card transparent">
          <div className="card card-dark-blue">
            <div className="card-body">
             
              <p className="fs-30 mb-2">61344</p>
              <p>Enrolled Devices</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
          <div className="card card-light-blue">
            <div className="card-body">
 
              <p className="fs-30 mb-2">34040</p>
              <p>Registered Devices</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 stretch-card transparent">
          <div className="card card-light-danger">
            <div className="card-body">
        
              <p className="fs-30 mb-2">Some data</p>
              <p>7684839</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 


</div>

        
  

    );
  }
}
