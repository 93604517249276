import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import CurrentDateTime from './CurrentDateTime';

export default class Header extends Component {
  render() {
    return (

      <div>
  <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
      <p>ScreenBros</p>
      {/* <Link className="navbar-brand brand-logo me-5" to="/"><img src="./assets/images/logo1.png" style={{width: '200%'}} className="me-5" alt="SB" /></Link>
      <Link className="navbar-brand brand-logo-mini" to="/"><img src="./assets/images/logo.png" alt="SB" /></Link> */}
    </div>
    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <button className="navbar-toggler sidebar-hidden navbar-toggler align-self-center" type="button" data-toggle="minimize">
        <span className="icon-menu" />
      </button>
      <ul className="navbar-nav mr-lg-2">
        <li className="nav-item nav-search d-none d-lg-block">
          <div className="input-group">
            <div className="input-group-prepend hover-cursor" id="navbar-search-icon">
             
            </div>
           
            <p style={{'color':'red'}}>Dear User your login access Expires on {localStorage.getItem("expiry")} . Login again </p> 
            <i className="mdi mdi-calendar" /> 
                <div><CurrentDateTime/></div>
          </div>
        </li>
      </ul>
      <ul className="navbar-nav navbar-nav-right">
        <li className="nav-item dropdown">
          {/* <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
            <i className="icon-bell mx-0" />
            <span className="count" />
          </a> */}
          {/* <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
            <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
            <a className="dropdown-item preview-item">
              <div className="preview-thumbnail">
                <div className="preview-icon bg-success">
                  <i className="ti-info-alt mx-0" />
                </div>
              </div>
              <div className="preview-item-content">
                <h6 className="preview-subject font-weight-normal">Application Error</h6>
                <p className="font-weight-light small-text mb-0 text-muted">
                  Just now
                </p>
              </div>
            </a>
            <a className="dropdown-item preview-item">
              <div className="preview-thumbnail">
                <div className="preview-icon bg-warning">
                  <i className="ti-settings mx-0" />
                </div>
              </div>
              <div className="preview-item-content">
                <h6 className="preview-subject font-weight-normal">Settings</h6>
                <p className="font-weight-light small-text mb-0 text-muted">
                  Private message
                </p>
              </div>
            </a>
            <a className="dropdown-item preview-item">
              <div className="preview-thumbnail">
                <div className="preview-icon bg-info">
                  <i className="ti-user mx-0" />
                </div>
              </div>
              <div className="preview-item-content">
                <h6 className="preview-subject font-weight-normal">New user registration</h6>
                <p className="font-weight-light small-text mb-0 text-muted">
                  2 days ago
                </p>
              </div>
            </a>
          </div> */}
        </li>
        <li className="nav-item nav-profile dropdown">
          {/* <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
            <img src="./assets/images/faces/face.jpg"  alt="pic" alt="profile" />
          </a> */}
          {/* <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
            <a className="dropdown-item">
              <i className="ti-settings text-primary" />
              Settings
            </a>
            <a className="dropdown-item">
              <i className="ti-power-off text-primary" />
              Logout
            </a>
          </div> */}
        </li>
        {/* <li className="nav-item nav-settings d-none d-lg-flex">
          <a className="nav-link" href="#">
            <i className="icon-ellipsis" />
          </a>
        </li> */}
      </ul>
      <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
        <span className="icon-menu" />
      </button>
    </div>
  </nav>
  
  </div>

    
        

    );
  }
}
