import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login.js';

import Home from './components/home.js';
import Device_detail from './components/device_detail.js';
import Notify from './components/notify.js';
import Release from './components/release.js';
import Template from './components/template';

import Lock_device from './components/lock_device.js';
import Unlock_device from './components/unlock_device.js';
import History from './components/history.js';
import Logout from './components/logout.js';
import BlockApp from './components/blockapp.js';
import UnblockApp from './components/unblockapp.js';


import Modal from 'react-modal';

export const BATCH_SIZE =30

export const APP_URL ="https://www.screenbros.co/webapi/"

const PrivateRoute = ({ element: Component }) => {
  const isAuthenticated = localStorage.getItem('loggedin') === 'true';
  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};
Modal.setAppElement('#root')
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
          <Route path="/" element={<Template />}>
          <Route index element={<PrivateRoute element={Home} />} />
          <Route path="lockDevice" element={<PrivateRoute element={Lock_device} />} />
          <Route path="blockApp" element={<PrivateRoute element={BlockApp} />} />
          <Route path="unblockapp" element={<PrivateRoute element={UnblockApp} />} />
          <Route path="unlockDevice" element={<PrivateRoute element={Unlock_device} />} />
          <Route path="release" element={<PrivateRoute element={Release} />} />
          <Route path="deviceDetails" element={<PrivateRoute element={Device_detail} />} />
          <Route path="notify" element={<PrivateRoute element={Notify} />} />
          {/* <Route path="report" element={<PrivateRoute element={Report} />} /> */}
          <Route path="reports" element={<PrivateRoute element={History} />} />
         
         
          <Route path="logout" element={<PrivateRoute element={Logout} />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
